'use strict';

import * as Interfaces from '../interfaces/models.interfaces';

export class VoterFraudIncident implements Interfaces.VoterFraudIncident {
    IncidentID: number;
    StateID: number;
    CategoryID: number;
    AllegationSummary: string;
    AllegationDetail: string;
    AllegationType: string;
    AffidavitDesc: string;
    IncidentWeightDesc: string;
    PotentialImpactDesc: string;
    VeracityScoreDesc: string;
    NumberOfVotesAffected: string;
    IncidentSummary: string;
    IncidentComments: string;
    LastUpdate: Date;
}

export class Params {
  stateID: string;
  categoryID: string;
}
