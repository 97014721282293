import { Component, Input, OnInit, OnChanges, ElementRef, ViewChild, ViewChildren, SimpleChange, SimpleChanges, OnDestroy, QueryList, Pipe, PipeTransform } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormControl, ValidatorFn, Validators, ReactiveFormsModule } from '@angular/forms';
import { HttpParams, HttpEventType, HttpClient, HttpHeaders, HttpRequest, HttpResponse } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

//import { NgbModal, NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

//import {ActiveToast, IndividualToastrConfig, ToastrService } from 'ngx-toaster';

import { FraudIncidentDataService } from '../services/fraud_incident.service';
import  *  as Classes from '../classes/models.classes'

@Pipe({ name: 'safeHtml' })
export class SafeHtmlPipe implements PipeTransform {
    constructor(private sanitized: DomSanitizer) { }
    transform(value) {
        return this.sanitized.bypassSecurityTrustHtml(value);
    }
}


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ["./home.component.css"]
})
export class HomeComponent implements OnInit, OnDestroy, OnChanges{


  @Input() Mode: string;
  @Input() StateID: string;
  @Input() CategoryID: string;

    FraudIncidentData: Array<Classes.VoterFraudIncident>;

    FirstTabChecked: boolean;
    SecondTabChecked: boolean;
    ThirdTabChecked: boolean;
    FourthTabChecked: boolean;

    Category: string;
    State: string;
    //@ViewChild('tab1Checkbox') tab1Checkbox: ElementRef;
    //@ViewChild('tab2') tab2Checkbox;
    //@ViewChild('tab3') tab3Checkbox;
    //@ViewChild('tab5') tab4Checkbox;

    @ViewChild('ulContent') ulElementView: QueryList<ElementRef>;

    @ViewChildren('ulContent') ulChildrenComponent: QueryList<ElementRef>; 
    @ViewChildren('liContent') liChildrenComponent: QueryList<ElementRef>;

    @ViewChildren('incidentDiv') incidentDivComponent: QueryList<ElementRef>;
    @ViewChildren('incidentDivHome') incidentDivHomeComponent: QueryList<ElementRef>;
    @ViewChildren('incidentDivAbout') incidentDivAboutComponent: QueryList<ElementRef>;

    ulContentHeight: number;

  constructor(
    private fraudIncidentDataService: FraudIncidentDataService,
      private _route: ActivatedRoute,
    private _router: Router
    //private formBuilder: FormBuilder
  ) {
    //this._route.queryParams.subscribe(params => {
    //  this.StateID = params['StateID'];
    //    this.CategoryID = params['CategoryID'];
    //    if (this.StateID != undefined && this.StateID != null || this.StateID != ''
    //        && this.CategoryID != undefined && this.CategoryID != null || this.CategoryID != '') {
    //        //this.getFraudIncidentData();
    //    }
    //});
    //  this._route.params.subscribe(params => {
    //      let id = params.get('StateID');
    //      this.StateID = params['StateID'];
    //      this.CategoryID = params['CategoryID'];
    //      if (this.StateID != undefined && this.StateID != null || this.StateID != ''
    //          && this.CategoryID != undefined && this.CategoryID != null || this.CategoryID != '') {
    //          //this.getFraudIncidentData();
    //      }
    //  });
      //console.log('this.StateID = ' + this.StateID + '; this.CategoryID = ' + this.CategoryID);
    //this.resetPage();
  }

  ngOnInit() {
    debugger;
      this.FraudIncidentData = new Array<Classes.VoterFraudIncident>();

    //Classes.VoterFraudIncident this.FraudIncidentData[] = [];
    //this.FraudIncidentData = [];
    //this.FraudIncidentData[0].AffidavitDesc = 'test';
    //this.FraudIncidentData: Classes.VoterFraudIncident[] = [];
      //const firstParam: string = this._route.snapshot.queryParamMap.get('StateID');
      //if (firstParam == undefined || firstParam == null || firstParam == '') {
      //    if (this.StateID == null || this.StateID == '') {
      //        this.StateID = '1';
      //    }
      //    else {
      //        this.StateID = firstParam
      //    }
      //}
      //const secondParam: string = this._route.snapshot.queryParamMap.get('StateID');
      //if (secondParam == undefined || secondParam == null || secondParam == '') {
      //    if (this.CategoryID == null || this.CategoryID == '') {
      //        this.CategoryID = '1';
      //    }
      //    else {
      //        this.CategoryID = secondParam
      //    }
      //}
      //console.log(this._route.snapshot.queryParams);

      this.FirstTabChecked = false;
      this.SecondTabChecked = false;
      this.ThirdTabChecked = false;
      this.FourthTabChecked = false;

      //if (this.StateID == null || this.StateID == '') {
      //    this.StateID = '1';
      //}
      //if (this.CategoryID == null || this.CategoryID == '') {
      //    this.CategoryID = '1';
      //}

    //this.StateID = '1';
    //this.CategoryID = '1';

      this.getFraudIncidentData();
      this.setCategoryAndStateName();
      //this.checkTabStatus();
      //console.log('this.StateID = ' + this.StateID + '; this.CategoryID = ' + this.CategoryID );

    }

    ngAfterViewInit() {

        this._route.queryParams.subscribe(params => {
            this.StateID = params['StateID'];
            this.CategoryID = params['CategoryID'];
            if (this.StateID != undefined && this.StateID != null && this.StateID != ''
                && this.CategoryID != undefined && this.CategoryID != null && this.CategoryID != '') {
                this.getFraudIncidentData();
            }
            else {
                this.StateID = '8';
                this.CategoryID = '1';
                this.getFraudIncidentData();
            }
        });


        //const firstParam: string = this._route.snapshot.queryParamMap.get('StateID');
        //if (firstParam == undefined || firstParam == null || firstParam == '') {
        //    if (this.StateID == null || this.StateID == '') {
        //        this.StateID = '1';
        //    }
        //}
        //else {
        //    this.StateID = firstParam
        //}
        //const secondParam: string = this._route.snapshot.queryParamMap.get('CategoryID');
        //if (secondParam == undefined || secondParam == null || secondParam == '') {
        //    if (this.CategoryID == null || this.CategoryID == '') {
        //        this.CategoryID = '1';
        //    }
        //}
        //else {
        //    this.CategoryID = secondParam
        //}
        console.log(this._route.snapshot.queryParams);
        //if (firstParam != undefined || firstParam != null || firstParam != ''
        //    && secondParam != undefined || secondParam != null || secondParam != '') {
            this.getFraudIncidentData();
        //}

        this.setCategoryAndStateName();


        //this._route.queryParams.subscribe(params => {
        //    this.StateID = params['StateID'];
        //    this.CategoryID = params['CategoryID'];
        //    if (this.StateID != undefined && this.StateID != null || this.StateID != ''
        //        && this.CategoryID != undefined && this.CategoryID != null || this.CategoryID != '') {
        //        this.getFraudIncidentData();
        //    }
        //});

        //this.ulContentHeight = this.ulElementView[0].nativeElement.offsetHeight;
        //console.log('ul height: ' + this.ulContentHeight);
        //this.ulChildrenComponent.changes.subscribe((ulComps: QueryList<ElementRef>) => {
        //    // Now you can access to the child component
        //    if (ulComps !== undefined && ulComps !== null) {

        //        ulComps.forEach((currentValue, index) => {
        //            //if (!currentValue.name) {
        //            //    ulComps.splice(index, 1);
        //            //}
        //            console.log('ul height: ' + ulComps.first.nativeElement.offsetHeight);

        //        });


        //    }
        //});
        //this.liChildrenComponent.changes.subscribe((liComps: QueryList<ElementRef>) => {
        //    // Now you can access to the child component
        //    if (liComps !== undefined && liComps !== null) {
        //        liComps.forEach((currentValue, index) => {
        //            //if (!currentValue.name) {
        //            //    ulComps.splice(index, 1);
        //            //}
        //            console.log('li height: ' + liComps.first.nativeElement.offsetHeight);

        //        });
        //        //console.log('li height: ' + liComps.first.nativeElement.offsetHeight);
        //    }
        //});

    }

      ngOnDestroy() {

      }
      ngOnChanges() {

        }

    setCategoryAndStateName() {
        switch (this.CategoryID) {
            case '1':
                this.Category = "Voter Fraud";
                break;
            case '2':
                this.Category = "Election Fraud";
                break;
            case '3':
                this.Category = "Election Irregularities";
                break;
            case '4':
                this.Category = "Vote Integrity";
                break;
            default:
                break;
        }

        switch (this.StateID) {
            case '1':
                this.State = "Overview";
                break;
            case '2':
                this.State = "Arizona";
                break;
            case '3':
                this.State = "Georgia";
                break;
            case '4':
                this.State = "Michigan";
                break;
            case '5':
                this.State = "Nevada";
                break;
            case '6':
                this.State = "Pennsylvania";
                break;
            case '7':
                this.State = "Wisconsin";
                break;
            default:
                break;
        }
    }


    checkTabStatus() {
        //var tab1Checkbox: HTMLInputElement = this.tab1Checkbox.nativeElement;
        if (this.CategoryID === '1') {
            this.FirstTabChecked = true;
            this.SecondTabChecked = false;
            this.ThirdTabChecked = false;
            this.FourthTabChecked = false;
            //this.tab1Checkbox.nativeElement.dispatchEvent(new Event('change'));
            //tab1Checkbox.checked = true;
        }
        if (this.CategoryID === '2') {
            this.FirstTabChecked = false;
            this.SecondTabChecked = true;
            this.ThirdTabChecked = false;
            this.FourthTabChecked = false;
            //tab1Checkbox.checked = false;
        }
        if (this.CategoryID === '3') {
            this.FirstTabChecked = false;
            this.SecondTabChecked = false;
            this.ThirdTabChecked = true;
            this.FourthTabChecked = false;
        }
        if (this.CategoryID === '4') {
            this.FirstTabChecked = false;
            this.SecondTabChecked = false;
            this.ThirdTabChecked = false;
            this.FourthTabChecked = true;
        }
        console.log('this.FirstTabChecked = ' + this.FirstTabChecked + '; this.SecondTabChecked = ' + this.SecondTabChecked + '; this.ThirdTabChecked = ' + this.ThirdTabChecked + '; this.FourthTabChecked = ' + this.FourthTabChecked);

    }

  getFraudIncidentData() {
    
    this.fraudIncidentDataService.loadFraudIncidentData(this.StateID, this.CategoryID).subscribe(data => {
      if (data.type === HttpEventType.DownloadProgress) {
        let percentDone: number = Math.round(100 + data.loaded / data.total);
        if (percentDone > 100) {
          percentDone = 100;
        }
        else if (percentDone < 0) {
          percentDone = 0;
        }
      } else if (data instanceof HttpResponse) {
        let loadedData: Array<Classes.VoterFraudIncident> = data.body;
        //this.FraudIncidentData = [...data.body];
        this.FraudIncidentData = loadedData;
        //this.FraudIncidentData = JSON.parse(JSON.stringify(loadedData));
        //data.body.forEach((elem: any) => {
        //  this.FraudIncidentData.push(elem);
        //});

        //this.FraudIncidentData = data.body.map(x => Object.assign({}, x));

        //this.FraudIncidentData.forEach((elem: any) => {
          //console.log(elem);
        //});

      }
    })
  };

    expandTab(tabID: number, event: Event) {
        let divHeight_lcl: number; 

        if (this.incidentDivComponent !== undefined && this.incidentDivComponent !== null) {
            var divElem = this.incidentDivComponent.toArray();
            divHeight_lcl = divElem[tabID].nativeElement.scrollHeight;

            this.incidentDivComponent.forEach((currentValue, index) => {
                if (index == tabID) {
                    currentValue.nativeElement.style.maxHeight = divHeight_lcl + 'px';
                }

            });

        }

    }

    minimizeTab(tabID: number, event: Event) {
        let divHeight_lcl: number = 170;

        if (this.incidentDivComponent !== undefined && this.incidentDivComponent !== null) {
            //var divElem = this.incidentDivComponent.toArray();
            //divHeight_lcl = divElem[tabID].nativeElement.scrollHeight;

            this.incidentDivComponent.forEach((currentValue, index) => {
                if (index == tabID) {
                    currentValue.nativeElement.style.maxHeight = divHeight_lcl + 'px';
                }

            });

        }

    }

    expandTabHome(tabID: number, event: Event) {
        let divHeight_lcl: number;

        if (this.incidentDivHomeComponent !== undefined && this.incidentDivHomeComponent !== null) {
            var divElem = this.incidentDivHomeComponent.toArray();
            divHeight_lcl = divElem[tabID].nativeElement.scrollHeight;

            this.incidentDivHomeComponent.forEach((currentValue, index) => {
                if (index == tabID) {
                    currentValue.nativeElement.style.maxHeight = divHeight_lcl + 'px';
                }

            });

        }

    }

    minimizeTabHome(tabID: number, event: Event) {
        let divHeight_lcl: number = 370;

        if (this.incidentDivHomeComponent !== undefined && this.incidentDivHomeComponent !== null) {
            //var divElem = this.incidentDivComponent.toArray();
            //divHeight_lcl = divElem[tabID].nativeElement.scrollHeight;

            this.incidentDivHomeComponent.forEach((currentValue, index) => {
                if (index == tabID) {
                    currentValue.nativeElement.style.maxHeight = divHeight_lcl + 'px';
                }

            });

        }

    }

  navigateTo(stateID: string, categoryID: string, event: Event) {
    this.StateID = stateID;
    this.CategoryID = categoryID;

    this._router.navigate([], {
      queryParams: {
        StateID: stateID,
        CategoryID: categoryID
      },
      queryParamsHandling: 'merge',
    });
      this.FraudIncidentData = new Array<Classes.VoterFraudIncident>();

      this.getFraudIncidentData();
      this.setCategoryAndStateName();
      //this.checkTabStatus();
      console.log('this.StateID = ' + this.StateID + '; this.CategoryID = ' + this.CategoryID);


    // changes the route without moving from the current view or
    // triggering a navigation event,
    //this._router.navigate([], {
    //  relativeTo: this._route,
    //  queryParams: {
    //    stateIDParams: '1'
    //  },
    //  // preserve the existing query params in the route
    //  //queryParamsHandling: 'merge',

    //  // do not trigger navigation
    //  skipLocationChange: true

    //});
  }

  navigateToFoo() {
    // changes the route without moving from the current view or
    // triggering a navigation event,
    this._router.navigate([], {
      relativeTo: this._route,
      queryParams: {
        stateIDParams: '1'
      },
      // preserve the existing query params in the route
      //queryParamsHandling: 'merge',

      // do not trigger navigation
      skipLocationChange: true

    });
  }

}
